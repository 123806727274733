<template>
  <div class="faq container">
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <figure>
            <div class="hero-body">
              <p class="title" style="">Frequently Asked Questions</p>
            </div>
          </figure>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="column is-centered">
        <template>
          <section>
            <b-collapse
              class="card"
              animation="slide"
              v-for="(faq, index) of faqs"
              :key="index"
              :open="isOpen == index"
              @open="isOpen = index"
            >
              <template #trigger="props">
                <div class="card-header" role="button">
                  <p class="card-header-title">
                    {{ faq.title }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  {{ faq.text }}
                </div>
              </div>
            </b-collapse>
          </section>
        </template>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <figure>
            <div class="hero-body">
              <p class="title">An Introduction From the Creator</p>
              <p class="head-description">
                All of your questions are answered in this video!
              </p>
            </div>
          </figure>
        </div>
      </div>
    </section>

    <div class="container" style="max-width: 1024px">
      <figure class="image is-16by9">
        <iframe
          class="has-ratio"
          width="1024"
          height="576"
          src="https://www.youtube.com/embed/p3KULwdBGqM"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  title: "porwod - FAQ",
  data() {
    return {
      isOpen: 0,
      faqs: [
        {
          title: "Will the final product be the same?",
          text: "95% yes. The layout for the some of the outputs can be changed.",
        },
        {
          title: "Will all versions be available for sale?",
          text: "This topic is basically about supply and demand balance. The design allows all versions.",
        },
        {
          title: "Does the 4-arm hinge create any safety risk?",
          text: "The hinges weren't covered to show the actual move of the upper part of the case. This movement protects the gasket from deformation. The gasket is needed for waterproofing. In the final product, the hinges will be guarded with a cover.",
        },
        {
          title: "Isn't it too heavy?",
          text: "Porwod is not only a laptop. When you want to go somewhere with your laptop, you have to carry all these things separately. As a result, the total weight of porwod is the same or less. But don't worry, Porwod will have a kit to carry it like a backpack.",
        },
        {
          title: "When will it be produced?",
          text: "Clearly saying, at least 9-12 months are required for detailed works and the prototype. If a manufacturer partner is found in this period, there can be a possibility for mass production in the very short term. For business corporation, please email: erkanakgul@gmail.com.",
        },
        {
          title: "Is that all?",
          text: "No 🙂 Porwod project has actually more options and properties. These can be thought of as versions.",
        },
        {
          title: "Are you sure porwod will be patented?",
          text: "About patent, we worked with patent services company. No doubt a serious pre-search was made.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.hero-body {
  text-align: center;
  font-family: $head-font;
  font-weight: 400;
  font-style: normal;
}

.faq {
  margin-block-end: 8rem;
}

.card-header-title {
  font-family: $head-font;
  font-size: 1.25rem;
}

.card-content {
  font-family: $desc-font;
  font-weight: 400;
}

.head-description {
  font-family: $desc-font;
  font-weight: 400;
  font-style: normal;
}
</style>
